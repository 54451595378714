import {
    LOADING_TOGGLE,
    TOGGLE_DRAWER,
    TODO_LOADING
} from '../constants'

const initialState = {
    is_loading: false,
    drawer: false,
    todoLoading: true
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_TOGGLE:
            return {
                ...state,
                is_loading: !state.is_loading
            }
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawer: !state.drawer
            }
        case TODO_LOADING:
            return {
                ...state,
                todoLoading: !state.todoLoading
            }
        default:
            return state
    }
}