import {
    LOGIN,
    SIGNUP,
    SIGNUP_URL,
    TOGGLE_LOGIN,
    UPDATE_USERID,
    UPDATE_USERNAME,
    LOGIN_URL
} from '../constants'

import Axios from 'axios'

// ===== 

const login = user => {
    return {
        type: LOGIN,
        payload: user
    }
} 

const signup = user => {
    return {
        type: SIGNUP,
        payload: user
    }
}

const toggleLogin = () => {
    return {
        type: TOGGLE_LOGIN
    }
}

export const updateUserId = (userId) => {
    return {
        type: UPDATE_USERID,
        payload: userId
    }
}

export const updateUsername = (username) => {
    return {
        type: UPDATE_USERNAME,
        payload: username
    }
}

// Async functions 

export const signupAsync = (email,username,password) => {
    return dispatch => {
        Axios({
            method: 'POST',
            url: SIGNUP_URL,
            data: {
                email: email,
                username: username,
                password: password
            }
        }).then(res => {
            console.log(res.data)
            dispatch(signup(res.data))
            dispatch(updateUserId(res.data._id))
            dispatch(updateUsername(res.data.username))
            dispatch(toggleLogin())
        }).catch(err => {
            console.log(err)
        })
    }
}


export const loginAsync = (username, password) => {
    return dispatch => {
        Axios({
            method: 'GET',
            url: LOGIN_URL,
            data: {
                username: username,
                password: password
            }
        }).then(res => {
            dispatch(login(res.data))
            dispatch(updateUserId(res.data._id))
            dispatch(updateUsername(res.data.username))
            dispatch(toggleLogin())
        }).catch(err=> {
            console.log(err)
        })
    }
}