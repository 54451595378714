/**
 * Action creators 
 */

import {
    LOADING_TOGGLE,
    TOGGLE_DRAWER,
    TODO_LOADING
} from '../constants'

export const toggleLoading = () => {
    return {
        type: LOADING_TOGGLE
    }
}

export const toogleDrawer = () => {
    return {
        type: TOGGLE_DRAWER
    }
}

export const todoLoadingToggle = () => {
    return {
        type: TODO_LOADING
    }
}