import React from 'react'
import {
    makeStyles,
    Container,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Box,
    Typography
} from '@material-ui/core'

import todo from '../images/todo.svg'
import Signup from '../components/auth/signup'
import Login from '../components/auth/login'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yprops(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyle = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3)
    },
    appbar: {
        flexGrow: 1
    },
    image: {
        marginTop: theme.spacing(9),
        [theme.breakpoints.down('xs')]:{
            marginTop: theme.spacing(2)
        }
    }
}))

const Auth = () => {

    const classes = useStyle()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <img src={todo} alt="" width="100%" className={classes.image} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className={classes.appbar}>
                            <AppBar position="static">
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                    <Tab label="signup" {...a11yprops(0)} />
                                    <Tab label="login" {...a11yprops(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <Signup />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Login />
                            </TabPanel>
                        </div>

                    </Grid>

                </Grid>

            </Container>
        </div>
    )
}

export default Auth