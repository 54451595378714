import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import {
    LOGIN_URL, LOGIN
} from '../../store/constants'

import {
    loginAsync
} from '../../store/actions/user'

import {
    makeStyles,
    Container,
    FormControl,
    TextField,
    Button
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {},
    input: {
        margin: theme.spacing(2, 1)
    },
    form: {
        marginTop: theme.spacing(6),
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(16)
        }
    }
}))

const Login = (props) => {
    const classes = useStyle()
    const [username, usernameHandler] = useState('')
    const [password, passwordHandler] = useState('')
    const history = useHistory()

    return (
        <Container className={classes.form}>
            <FormControl fullWidth >
                <TextField
                    variant="outlined"
                    label="Email"
                    type="text"
                    className={classes.input}
                    value={username}
                    onChange={(event) => { usernameHandler(event.target.value) }}
                />
                <TextField
                    variant="outlined"
                    label="password"
                    type="password"
                    className={classes.input}
                    value={password}
                    onChange={(event) => { passwordHandler(event.target.value) }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.input}
                    onClick={() => {
                        Axios({
                            method: 'POST',
                            url: LOGIN_URL,
                            data: {
                                "email": "sdg@gmail",
                                "password": "12345"
                            }
                        }).then(res => {
                            console.log(res.data)
                            localStorage.setItem('token', res.data.token)
                            window.location.reload()
                        }).catch(err => {
                            console.log(err)
                        })
                    }}
                >Login</Button>
            </FormControl>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        isLogin: state.user.isLoggedIn,
        userId: state.user.userId,
        username: state.user.username
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(loginAsync(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)