/**
 * For running async code inside our reducers 
 * we need to use redux-thunk and also create 
 * action creators inside that will return a action. 
 */

/**
 * This file contains all the action creators, 
 * About: Action creators are function that dispatch a action.
 */


import {
    FETCH_TODOS,
    FETCH_LISTS,
    UPDATE_LIST_INDEX,
    CREATE_LIST,
    UPDATE_LIST_INPUT,
    UPDATE_TODO_INPUT,
    LIST_URL,
    TODO_URL
} from '../constants'

import {
    toggleLoading,
    todoLoadingToggle
} from './ui'
import Axios from 'axios'

/**
 * This action updates the todos state with the new
 * payload value.
 */
const fetchTodos = (data) => {
    return {
        type: FETCH_TODOS,
        payload: data
    }
}

/**
 * This action updates the lists and the 
 * currentListIndex
 */
const fetchLists = (data, listIndex) => {
    return {
        type: FETCH_LISTS,
        payload: {
            data: data,
            listIndex: listIndex
        }
    }
}

/**
 * Updates the list Index
 */
export const updateListIndex = (data) => {
    return {
        type: UPDATE_LIST_INDEX,
        payload: data
    }
}

/**
 * Creates a new list 
 */
const createList = (data) => {
    return {
        type: CREATE_LIST,
        payload: data
    }
}

/**
 * updates the list input 
 */
export const updateListInput = (data) => {
    return {
        type: UPDATE_LIST_INPUT,
        payload: data
    }
}

/**
 * updates to todo input
 */
export const updateTodoInput = (data) => {
    return {
        type: UPDATE_TODO_INPUT,
        payload: data
    }
}

/**
 * Async functions 
 */
export const fetchTodoAsync = (token) => {
    console.log(token)
    return dispatch => {
        Axios({
            method: 'GET',
            url: TODO_URL,
            headers: {Authorization: `Bearer ${token}`}
        }).then(res => {
            console.log(res.data)
            dispatch(todoLoadingToggle())
            dispatch(fetchTodos(res.data))
        }).catch(err => {
            console.log(err)
        })
    }

}

export const fetchListAsync = (token) => {
    return dispatch => {
        Axios({
            method: 'GET',
            url: LIST_URL,
            headers: {Authorization: `Bearer ${token}`}
        }).then(res => {
            console.log(res.data)
            dispatch(fetchLists(res.data, res.data[0]._id))
        }).catch(err => {
            console.log('action error',err)
        })
    }
}

export const createTodoAsync = (listId, name, token) => {
    if (name === '') {
        return dispatch => { }
    }
    return dispatch => {
        dispatch(toggleLoading())
        Axios({
            method: 'POST',
            url: TODO_URL,
            headers: {Authorization: `Bearer ${token}`},
            data: {
                listId: listId,
                name: name
            }
        }).then(response => {
            return Axios({
                method: 'GET',
                url: TODO_URL,
                headers: {Authorization: `Bearer ${token}`}
            })
        }).then(res => {
            console.log(res)
            dispatch(fetchTodos(res.data))
            dispatch(updateTodoInput(''))
            dispatch(toggleLoading())
        }).catch(err => {
            console.log(err)
        })
    }
}

export const createListAsync = (listName, token) => {
    if (listName === '') {
        return dispatch => { }
    }
    return dispatch => {
        dispatch(toggleLoading())
        Axios({
            method: 'POST',
            url: LIST_URL,
            headers: {Authorization: `Bearer ${token}`},
            data: {
                name: listName
            }
        }).then(res => {
            dispatch(createList(res.data))
            dispatch(updateListInput(''))
            dispatch(toggleLoading())
        }).catch(err => {
            console.log(err)
        })
    }
}

export const deleteTodoAsync = (todoId, token) => {

    return dispatch => {
        dispatch(toggleLoading())
        Axios({
            method: 'DELETE',
            url: TODO_URL,
            headers: {Authorization: `Bearer ${token}`},
            data: {
                todoId: todoId
            }
        }).then(() => {
            return Axios({
                method: 'GET',
                url: TODO_URL,
                headers: {Authorization: `Bearer ${token}`}
            })
        }).then(res => {
            dispatch(fetchTodos(res.data))
            dispatch(toggleLoading())
        }).catch(err => {
            console.log(err)
            dispatch(toggleLoading())
        })
    }
}

export const deleteListAsync = (listId, token) => {
    return dispatch => {
        dispatch(toggleLoading())
        Axios({
            method: 'DELETE',
            url: LIST_URL,
            headers: {Authorization: `Bearer ${token}`},
            data: {
                id: listId
            }
        }).then((result) => {
            console.log(result)
            return Axios({
                method: 'GET',
                url: LIST_URL,
                headers: {Authorization: `Bearer ${token}`}
            })
        }).then(res => {
            dispatch(fetchLists(res.data, res.data[0]._id))
            dispatch(toggleLoading())
        }).catch(err => {
            console.log(err)
            dispatch(toggleLoading())
        })
    }
}