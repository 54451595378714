import React from 'react'
import { useCookies } from 'react-cookie'
import { connect } from 'react-redux'
import {
    updateUserId,
    updateUsername
} from '../../store/actions/user'

import {
    fetchListAsync,
    fetchTodoAsync
} from '../../store/actions/main'

// components 
import Index from '../../pages/index'
import Auth from '../../pages/auth'

const Decider = (props) => {

    const [cookies, setCookie, removeCookie] = useCookies(['isLogin', 'userId', 'username'])
    const token = localStorage.getItem('token')

    // if (cookies.isLogin) {
    //     console.log(cookies.userId)
    //     props.updateUserId(cookies.userId)
    //     props.updateUsername(cookies.username)
    //     return <Index />
    // }

    if (token) {
        props.fetchLists(token)
        props.fetchTodo(token)
        return <Index />
    }

    return <Auth />

}

const mapStateTpProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserId: (id) => dispatch(updateUserId(id)),
        updateUsername: (username) => dispatch(updateUsername(username)),
        fetchLists: (userId) => dispatch(fetchListAsync(userId)),
        fetchTodo: (token) => dispatch(fetchTodoAsync(token))
    }
}

export default connect(mapStateTpProps, mapDispatchToProps)(Decider)