import React from 'react';
import Navbar from './components/navbar'
import { connect } from 'react-redux'
import {withCookies} from 'react-cookie'

import {
  fetchTodoAsync,
  fetchListAsync,
} from './store/actions/main'

import { Switch, Route } from 'react-router-dom'

import Index from './pages/index'
import Auth from './pages/auth'
import Decider from './components/auth/decider'

class App extends React.Component {

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/">
            <Decider />
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/test">
            <Index/>
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/auth">
            <Auth />
          </Route>
        </Switch>

      </div>
    );
  }

}

const mapStateToProps = state => {
  return {}
}


const mapDispatchToProps = dispatch => {

  return {}
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));
