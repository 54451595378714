import React, { useState } from 'react'
import { connect } from 'react-redux'

// actions 
import {
    createListAsync,
    createTodoAsync
} from '../../store/actions/main'

import {
    makeStyles,
    Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    TextField,
    Button
} from '@material-ui/core'

import {
    Add
} from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
    root: {
        margin: '0px',
        top: 'auto',
        right: '20px',
        bottom: '20px',
        left: 'auto',
        position: 'fixed'
    }
}))

const FloatingButton = (props) => {
    const classes = useStyle()

    const [dialog, dialoghandler] = useState(false)
    const [text, textHandler] = useState('')
    const [listCheck, listCheckHandler] = useState(false)
    const [todoCheck, todoCheckHandler] = useState(false)

    let token = localStorage.getItem('token')


    return (
        <div className={classes.root}>
            <Fab
                color="primary"
                onClick={() => { dialoghandler(true) }}
            >
                <Add />
            </Fab>

            <Dialog
                open={dialog}
                onClose={() => { dialoghandler(false) }}
            >
                <DialogTitle>
                    Create a new List or Todo
            </DialogTitle>

                <DialogContent>
                    <FormControlLabel
                        control={<Checkbox
                            checked={listCheck}
                            onChange={(event) => {
                                todoCheckHandler(false)
                                listCheckHandler(true)
                            }}
                        />}
                        label="List"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={todoCheck}
                            onChange={() => {

                                listCheckHandler(false)
                                todoCheckHandler(true)
                            }}
                        />}
                        label="Todo"
                    />
                    <TextField value={text} onChange={(event) => { textHandler(event.target.value) }} />
                </DialogContent>

                <DialogActions>

                    <Button
                        onClick={() => {
                            if (text === '') {
                                return
                            }


                            if (listCheck) {
                                props.createList(text, token)
                                textHandler('')
                                dialoghandler(false)
                            }
                            if (todoCheck) {
                                props.createTodo(props.listIndex, text, token)
                                textHandler('')
                                dialoghandler(false)
                            }
                        }}
                    >Create</Button>
                    <Button
                        onClick={() => { dialoghandler(false) }}
                    >Cancel</Button>

                </DialogActions>

            </Dialog>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        listIndex: state.main.currentListIndex
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createTodo: (listId, name, token) => dispatch(createTodoAsync(listId, name, token)),
        createList: (listName, token) => dispatch(createListAsync(listName, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloatingButton)