/**
 * This file will contain all the 
 * actions constant strings 
 */

export const LOADING_TOGGLE = "LOADDING_TOGGLE"
export const FETCH_TODOS = "FETCH_TODOS"
export const FETCH_LISTS = "FETCH_LISTS"
export const UPDATE_LIST_INDEX = "UPDATE_LIST_INDEX"
export const CREATE_TODO = "CREATE_TODO"
export const CREATE_LIST = "CREATE_LIST"
export const UPDATE_LIST_INPUT = "UPDATE_LIST_INPUT"
export const UPDATE_TODO_INPUT = "UPDATE_TODO_INPUT"
export const DELETE_TODO = "DELETE_TODO"
export const TOGGLE_DRAWER = "TOGGLE_DRAWER"
export const TODO_LOADING = "TODO_LOADING"
export const LOGIN = "LOGIN"
export const SIGNUP = "SIGNUP"
export const TOGGLE_LOGIN = "TOGGLE_LOGIN"
export const UPDATE_USERNAME = "UPDATE_USERNAME"
export const UPDATE_USERID = "UPDATE_USERID"


// URLS 
export const BASE_URL = "https://stack-hack.herokuapp.com"
export const LIST_URL = BASE_URL + "/todo/lists"
export const TODO_URL = BASE_URL + "/todo/todos"
export const LOGIN_URL = BASE_URL + "/auth/login"
export const SIGNUP_URL = BASE_URL + "/auth/signup"