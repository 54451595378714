import {combineReducers} from 'redux'
import main from './reducers/main'
import ui from './reducers/ui'
import user from './reducers/user'

const rootReducer = combineReducers({
    main: main,
    ui: ui,
    user: user
})


export default rootReducer