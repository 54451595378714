import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'
import {useCookies} from 'react-cookie'
import Axios from 'axios'
import {
    SIGNUP_URL
} from '../../store/constants'

import {
    makeStyles,
    Container,
    FormControl,
    TextField,
    Button
} from '@material-ui/core'

// State management 

import { connect } from 'react-redux'
import {
    signupAsync
} from '../../store/actions/user'

const useStyle = makeStyles(theme => ({
    root: {},
    input: {
        margin: theme.spacing(2, 1)
    },
    form: {
        marginTop: theme.spacing(6),
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(16)
        }
    }
}))



const Signup = (props) => {
    const location = useHistory()
    const classes = useStyle()
    const [username, usernameHandler] = useState('')
    const [email, emailHandler] = useState('')
    const [password, passwordHandler] = useState('')
    const [cookies, setCookie,removeCookie] = useCookies(['isLogin', 'userId', 'username'])

    if(cookies.isLogin){
        return (
            <div>
                {location.push('/')}
            </div>
        )
    }

    if(props.isLogin){
        setCookie('isLogin', true)
        setCookie('userId', props.userId)
        setCookie('username', props.username)
        console.log('cok', cookies)
        console.log(props.isLogin)
    }

    console.log(props.isLogin)

    return (
        <Container className={classes.form}>
            <FormControl fullWidth >
                <TextField
                    variant="outlined"
                    label="Email"
                    type="email"
                    className={classes.input}
                    value={email}
                    onChange={(event) => { emailHandler(event.target.value) }}
                />
                <TextField
                    variant="outlined"
                    label="Username"
                    type="text"
                    className={classes.input}
                    value={username}
                    onChange={(event) => { usernameHandler(event.target.value) }}
                />
                <TextField
                    variant="outlined"
                    label="password"
                    type="password"
                    className={classes.input}
                    value={password}
                    onChange={(event) => { passwordHandler(event.target.value) }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.input}
                    onClick={() => { 
                        Axios({
                            method: 'POST',
                            url: SIGNUP_URL,
                            data: {
                                email: email,
                                username: username,
                                password: password
                            }
                        }).then(res => {
                            localStorage.setItem('token', res.data.token)
                            window.location.reload()
                        }).catch(err => {
                            console.log(err)
                        })
                     }}
                >Signup</Button>
            </FormControl>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        isLogin: state.user.isLoggedIn,
        userId: state.user.userId,
        username: state.user.username
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signup: (email, username, password) => dispatch(signupAsync(email, username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)