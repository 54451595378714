import {
    LOGIN,
    SIGNUP,
    TOGGLE_LOGIN,
    UPDATE_USERID,
    UPDATE_USERNAME
} from '../constants'

const initialState = {
    user: {},
    isLoggedIn: false,
    userId: '',
    username: ''
}

export default (state = initialState, action) => {
    switch(action.type){

        case SIGNUP:
            return  {
                ...state,
                user: action.payload
            }
        
        case TOGGLE_LOGIN:
            return {
                ...state,
                isLoggedIn: !state.isLoggedIn
            }

        case UPDATE_USERID:
            return {
                ...state,
                userId: action.payload
            }
        
        case UPDATE_USERNAME:
            return {
                ...state,
                username: action.payload
            }
        default:
            return state
    }
}