import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'
import Navbar from '../components/navbar'

import {
    Grid,
    Hidden,
    Container,
    Backdrop,
    CircularProgress,
    makeStyles
} from '@material-ui/core'

import Todo from '../components/todolist'
import List from '../components/lists'
import FloatingButton from '../components/buttons/floatingbutton'

import {
    fetchTodoAsync,
    fetchListAsync,
    updateListIndex,
    createTodoAsync,
    createListAsync,
    updateListInput,
    updateTodoInput,
    deleteTodoAsync,
    deleteListAsync
} from '../store/actions/main'

import {
    toggleLoading
} from '../store/actions/ui'

const useStyle = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    }
}))

const Index = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['isLogin', 'userId', 'username'])

    let userId = cookies.userId

    let token = localStorage.getItem('token')

    const classes = useStyle()

    return (
        <div>
            <Backdrop
            open={props.isLoading}
            className={classes.backdrop}
            >
                <CircularProgress />
            </Backdrop>
            <Navbar />

            <Container>
                <Grid container spacing={2}>

                    <Hidden xsDown>

                        <Grid item sm={4} xs={12}>
                            <List
                                items={props.lists}
                                selectedItem={props.listIndex}
                                selectedItemHandler={(id) => { props.updateListIndex(id) }}
                                btn={props.deleteList}
                            />

                        </Grid>

                    </Hidden>

                    <Grid item sm={8} xs={12}>

                        <Todo
                            todos={props.todos}
                            listId={props.listIndex}
                            btn={props.deleteTodo}
                        />

                    </Grid>

                </Grid>
            </Container>

            <FloatingButton
                userId={userId}
            />



        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.is_loading,
        todos: state.main.todos,
        lists: state.main.lists,
        listIndex: state.main.currentListIndex,
        listInput: state.main.listInput,
        todoInput: state.main.todoInput,
        userId: state.user.userId
    }
}

const mapDispatchToProps = dispatch => {

    return {
        toggleLoading: () => dispatch(toggleLoading()),
        fetchTodos: (id) => dispatch(fetchTodoAsync(id)),
        fetchLists: (id) => dispatch(fetchListAsync(id)),
        updateListIndex: (id) => dispatch(updateListIndex(id)),
        createTodo: (listId, name, id) => dispatch(createTodoAsync(listId, name, id)),
        createList: (listName, id) => dispatch(createListAsync(listName, id)),
        updateListInput: (data) => dispatch(updateListInput(data)),
        updateTodoInput: (data) => dispatch(updateTodoInput(data)),
        deleteTodo: (id, userId) => dispatch(deleteTodoAsync(id, userId)),
        deleteList: (id, userId) => dispatch(deleteListAsync(id, userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)